import React from 'react';

import SEO from '../../components/SEO';
import HelpLayout from '../../components/HelpLayout';
import HelpArticleItem from '../../components/HelpArticleItem';
import Card from '../../components/Card';

const HelpPage = ({ location }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="Help center"
        description="If you need help, we're here for you. Choose from the articles on various topics."
      />
      <Card>
        <Card.Heading>Articles</Card.Heading>
        <Card.Body>
          <ul>
            <HelpArticleItem
              href="/help/articles/how-to-upload-video/"
              title="How to upload a video"
            />
            <HelpArticleItem
              href="/help/articles/how-to-add-video-in-html/"
              title="How to add a video in HTML"
            />
            <HelpArticleItem
              href="/help/articles/how-to-add-video-in-wix/"
              title="How to add a video in Wix"
            />
            <HelpArticleItem
              href="/help/articles/how-to-add-video-in-wordpress-elementor/"
              title="How to add a video in Wordpress Elementor"
            />
            <HelpArticleItem
              href="/help/articles/how-to-add-video-in-squarespace/"
              title="How to add a video in Squarespace"
            />
          </ul>
        </Card.Body>
      </Card>
    </HelpLayout>
  );
};

export default HelpPage;
