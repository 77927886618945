import React from 'react';
import { Link } from 'gatsby';

const HelpArticleItem = ({ href, title }) => {
  return (
    <li className="mb-4 bg-white rounded-lg last:mb-0 lg:mb-6">
      <Link to={href} className="block p-4">
        {title}
      </Link>
    </li>
  );
};

export default HelpArticleItem;
